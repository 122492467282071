var lazyAnimations = {
    init: function() {
        var log = bows('lazyAnimations');
        this.lA(log);
    },

    lA: function () {
        
        var acc = document.getElementsByClassName('project-card');
        var i;
        var pcow = document.getElementsByClassName('project-card__overlay');
        var pcd = document.getElementsByClassName('project-card__divider');
        
        jQuery(pcow).addClass("hidden");
       
        jQuery(acc).each(function() {
            jQuery(this).hover(function() {
                jQuery(pcow, this).removeClass("hidden");
                }, function() {
                jQuery(pcow, this).addClass("hidden");
                });
            jQuery(this).hover(function() {
                jQuery(pcd, this).addClass("project-card__divider--hover");
                }, function() {
                jQuery(pcd, this).removeClass("project-card__divider--hover");
            }); 
        });
    }      
}