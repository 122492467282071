/**
 * site
 **/


// Initilize debugging with 'localStorage.debug = true' in your console
var log = bows('site');

// log('some var we need to check');

var site = {
  init: function() {
    // site.reframe();
    // modalJs.open('.trigger-modal', 'Something cool here!');
  },
  reframe: function() {
    // reframe('iframe[allowfullscreen]');
  }
};

var debounceActions = function() {
  // add JS function calls here for resize actions
};
var debounceCallable = debounce(debounceActions, 250);

jQuery(document).ready(function() {
  site.init();
  slider.init();
  jQuery('.single-portfolio__gallery-container').colorbox({
    slideshow: true,
    slideshowSpeed: 6000, 
    transition: "none",
    close: function() {
      return '<span class="fa fa-window-close"></span>';
    }, 
    maxWidth: "100%",
    maxHeight: "75%", 
    opacity: .9,
    rel: 'single-portfolio__gallery-container', 
    previous: function() {
      return '<span class="colorbox-icon fa fa-chevron-left"></span>';
    },
    next: function() {
      return '<span class="colorbox-icon fa fa-chevron-right"></span>';
    },
  });
});

jQuery(window).resize(function() {
  debounceCallable();
});
