/**
 * mobileMenu
 **/

// Initilize debugging with 'localStorage.debug = true' in your console
var log = bows("mobileMenu");

//log('some var we need to check');

var mobileMenu = {
    init : function() {
		this.mobileMenuShowHide();
        this.mobileMenuChildShowHide();
	},
    mobileMenuShowHide : function() {
	    jQuery('#mobile-menu-trigger').click(function() {
	        jQuery('#section-mobile-menu').toggleClass('show');
        });
    },
    mobileMenuChildShowHide : function() {
        // set the icons
        // set the trigger to open close
	    jQuery('#section-mobile-menu').find('.sub-menu').each(function() {
            var childMenu = jQuery(this);
            var parentClone = childMenu.parent().clone();
            parentClone.attr({
                'id': parentClone.attr('id') + '-clone'
            });
            var parentLink = childMenu.prev();
            var parentLinkClone = parentLink.clone();
            parentLink.append('<span class="fa fa-angle-down"></span>');
            parentLink.click(function(e) {
                e.preventDefault();
                childMenu.slideToggle(100);
            });
            parentClone.html(parentLinkClone);
            childMenu.prepend(parentClone);
        });
    }
};
// not sure if this calls the mobileMenu
jQuery(document).ready(function() {
    mobileMenu.init();
});
