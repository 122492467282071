/**
 * slider
 **/

var slider = {
  
    init: function() {
      var log = bows('slider');
      this.sliderSlides(log);
      this.bannerSlides(log);
      
    },
    sliderSlides: function(log) {
      // Check that tiny slider is initialized
      if (typeof tns === 'function') {
        var tnsCarousel = document.querySelectorAll('.slider');
        tnsCarousel.forEach(function(slider) {
          var slider = tns({
            mode: 'gallery',
            autoplay: true,
            autoplayButtonOutput: false,
            autoplayTimeout: siteObject.hero,
            controls: false,
            container: slider,
            gutter: 15,
            items: 1,
            nav: false,
            mouseDrag: true,
            speed: 500,
            preventScrollOnTouch: 'auto',
            responsive: {
              576: {
                gutter: 0,
                items: 1,
                slideBy: 1
              },
              992: {
                gutter: 0,
                items: 1,
                slideBy: 1
              },
              1200: {
                gutter: 0,
                items: 1,
                slideBy: 1
              }
            }
          });
        });
      }
    },
    bannerSlides: function(log) {
      // Check that tiny slider is initialized
      if (typeof tns === 'function') {
        var tnsCarousel = document.querySelectorAll('.banner');
        tnsCarousel.forEach(function(banner) {
          var banner = tns({
            autoplay: true,
            autoplayButtonOutput: false,
            autoplayTimeout: siteObject.banner,
            controls: false,
            container: banner,
            gutter: 0,
            items: 1,
            nav: false,
            speed: 500,
            mouseDrag: true,
            preventScrollOnTouch: 'auto',
            responsive: {
              576: {
                gutter: 0,
                items: 2,
                slideBy: 1
              },
              992: {
                gutter: 0,
                items: 4,
                slideBy: 1
              },
              1200: {
                gutter: 0,
                items: 5,
                slideBy: 1
              }
            }
          });
        });
      }
    }
  };
 